<template>
  <div>
    <b-list-group class="w-100 pointer pt-5">
      <router-link
        v-for="bundle in products"
        :to="{ name: 'product-details', params: { ...routeParams, productId: bundle.id } }"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h3 class="btn-link">
              {{ bundle.name }}
            </h3>
            <h5>{{ bundleLabel(bundle) }}</h5>
          </div>
          <div>
            <feather-icon type="chevron-right" />
          </div>
        </div>
      </router-link>
    </b-list-group>
  </div>
</template>

<script>
  import FeatherIcon from '@/components/shared/FeatherIcon'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ChooseBundle',

    components: {
      FeatherIcon,
    },

    computed: {
      ...mapGetters('checkout', ['products']),

      routeParams() {
        return this.$store.state.appRouter.params
      },
    },

    mounted() {
      this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })
    },

    methods: {
      bundleLabel(bundle) {
        const itemNames = bundle.products
          .map(product => product.name)
          .join(', ')

        return `$${bundle.price} plus state fees - ${itemNames}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  h3 {
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  h5 {
    margin-top: .25rem;
    margin-bottom: .5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>

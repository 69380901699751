var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-list-group",
        { staticClass: "w-100 pointer pt-5" },
        _vm._l(_vm.products, function (bundle) {
          return _c(
            "router-link",
            {
              staticClass: "list-group-item list-group-item-action",
              attrs: {
                to: {
                  name: "product-details",
                  params: { ..._vm.routeParams, productId: bundle.id },
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", [
                    _c("h3", { staticClass: "btn-link" }, [
                      _vm._v(
                        "\n            " + _vm._s(bundle.name) + "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.bundleLabel(bundle)))]),
                  ]),
                  _c(
                    "div",
                    [_c("feather-icon", { attrs: { type: "chevron-right" } })],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }